import React  from "react";
import ProductLists from "../../components/shopping/ProductLists";
import { ApiHook } from "../../hooks/apiHook";

const ShoppingLayout = () => {
    const items = ApiHook.CallRepurchaseItems()
    return (
        <>
            <div className="page_head_top">Shopping</div>
            <div className="ewallet_top_btn_sec">
                <div className="row justify-content-between">
                    <div className="col-md-4 text-end">
                        <div className="dropdown btn-group top_right_pop_btn_position">
                            <a className="top_righ_pop_btn" href="#">Repurchase Report</a>
                        </div>
                    </div>
                </div>
            </div>
            <ProductLists products={items?.data} />
        </>
    )
}

export default ShoppingLayout