import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { useReactToPrint } from "react-to-print";
import { ApiHook } from "../../hooks/apiHook";
import { useSelector } from "react-redux";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { formatDate } from "../../utils/formateDate";
import Loader from "react-js-loader";

const PurchaseInvoice = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const tableRef = useRef();
  const orderId = params.id;

  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );
  const userSelectedCurrency = useSelector(
    (state) => state?.user?.selectedCurrency
  );

  const handlePrint = useReactToPrint({
    content: () => tableRef.current,
  });

  const handleBack = () => {
    navigate("/invoice");
  };

  //---------------------------------- API ------------------------------------
  const invoice = ApiHook.CallInvoice(orderId);
  console.log(invoice.data, "invoice");
  if (invoice.isLoading) {
    return (
      <>
        <Loader type="bubble-scale" bgColor={"black"} size={30} />
      </>
    );
  }
  if (invoice.data && invoice.data.id) {
    return (
      <>
        <div className="page_head_top">{t("purchase_invoice")}</div>
        <div className="row">
          <div className="col-md-8 m-auto">
            <div className="purchase_invoice" ref={tableRef}>
              <button
                className="print-button"
                style={{ backgroundColor: "white" }}
                onClick={handleBack}
              >
                <i
                  className="fa fa-angle-left"
                  style={{ fontSize: "1.3em" }}
                ></i>
              </button>
              <div className="row p-2">
                <div
                  className="col-md-6 d-flex"
                  style={{ flexDirection: "column", alignItems: "flex-start" }}
                >
                  <img src="/images/logo_user.png" alt="" />
                  <div className="col-md-6">
                    <p className="font-weight-bold mb-4">Company Information</p>
                    <p className="mb-1">{invoice?.data?.companyInfo?.name}</p>
                    <p className="mb-1">
                      {invoice?.data?.companyInfo?.address}
                    </p>
                    <p className="mb-1">
                      Phone : {invoice?.data?.companyInfo?.phone}
                    </p>
                    <p className="mb-1">
                      E-mail : {invoice?.data?.companyInfo?.email}
                    </p>
                    <p className="mb-1">
                      {" "}
                      TAX Number: {invoice?.data?.companyInfo?.taxNumber}
                    </p>
                  </div>
                  {/* <p className="text-muted">
                    {invoice?.data?.companyInfo?.name}
                  </p>
                  <p className="text-muted">
                    {invoice?.data?.companyInfo?.address}
                  </p>
                  <p className="text-muted">
                    Phone : {invoice?.data?.companyInfo?.phone}
                  </p>
                  <p className="text-muted">
                    E-mail : {invoice?.data?.companyInfo?.email}
                  </p>
                  <p className="text-muted">
                    TAX : {invoice?.data?.companyInfo?.taxNumber}
                  </p> */}
                </div>
                <div className="col-md-6 text-end">
                  <p className="font-weight-bold mb-1">
                    {`${t("invoice")}: #${invoice.data?.id}`}
                  </p>
                  <p className="text-muted">
                    Date: {formatDate(invoice.data?.date)}
                  </p>
                  <button
                    type="button"
                    className="btn btn-labeled btn-primary print-button"
                    onClick={handlePrint}
                  >
                    <span className="btn-label">
                      <i className="fa fa-print"></i>
                    </span>{" "}
                    {t("print")}
                  </button>
                </div>
              </div>

              <hr className="my-1" />

              <div className="row pb-1 p-1">
                <div className="col-md-6">
                  <p className="font-weight-bold mb-4">
                    {t("client_information")}
                  </p>
                  <p className="mb-1">{invoice?.data?.clientInfo?.name}</p>
                  <p className="mb-1">{invoice.data?.clientInfo?.email}</p>
                  <p className="mb-1"> {invoice?.data?.clientInfo?.country}</p>
                  <p className="mb-1">{invoice.data?.clientInfo?.mobile}</p>
                </div>

                <div className="col-md-6 text-end">
                  <p className="font-weight-bold mb-4">
                    {t("payment_details")}
                  </p>
                  <p className="mb-1">
                    <span className="text-muted">{t("paymentMethod")}: </span>
                    {invoice?.paymentMethod}
                  </p>
                </div>
              </div>

              <div className="row p-1">
                <div className="col-md-12">
                  <table className="table">
                    <thead>
                      <tr>
                        {/* <th className="text-uppercase small font-weight-bold">
                            #
                          </th> */}
                        <th className="text-uppercase small font-weight-bold">
                          {t("type")}
                        </th>
                        <th className="text-uppercase small font-weight-bold">
                          {t("date")}
                        </th>
                        <th className="text-uppercase small font-weight-bold text-end">
                          {t("amount")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr key={invoice?.data?.id}>
                        <td>{invoice?.data?.type}</td>
                        <td>{invoice?.data?.date}</td>
                        <td className="text-end">
                          {`${
                            userSelectedCurrency.symbolLeft
                          } ${CurrencyConverter(
                            invoice?.data?.amount,
                            conversionFactor
                          )}`}
                        </td>
                      </tr>
                      <tr>
                        <td>TAX</td>
                        <td></td>
                        <td className="text-end">{`${
                          userSelectedCurrency.symbolLeft
                        } ${CurrencyConverter(
                          invoice?.data?.totalAmount - invoice?.data?.amount,
                          conversionFactor
                        )}`}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="d-flex flex-row-reverse text-white invoice-total p-4">
                <div className="py-1 px-1 text-end">
                  <div className="mb-2" style={{ color: "black" }}>
                    {t("grand_total")}
                  </div>
                  <div
                    className="h2 font-weight-light"
                    style={{ color: "black" }}
                  >
                    {`${userSelectedCurrency.symbolLeft} ${CurrencyConverter(
                      invoice.data?.totalAmount,
                      conversionFactor
                    )}`}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="page_head_top">{t("Invoice")}</div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <h6>Invoice Details Doesn't Exist</h6>
        </div>
      </>
    );
  }
  //   return (
  //     <>
  //       <div className="page_head_top">{t("purchase_invoice")}</div>
  //       <div className="row">
  //         <div className="col-md-8 m-auto">
  //           <div className="purchase_invoice" ref={tableRef}>
  //             <button
  //               className="print-button"
  //               style={{ backgroundColor: "white" }}
  //               onClick={handleBack}
  //             >
  //               <i className="fa fa-angle-left" style={{ fontSize: "1.3em" }}></i>
  //             </button>
  //             <div className="row p-2">
  //               <div className="col-md-6 d-flex align-items-center">
  //                 <img src="/images/logo_user.png" alt="" />
  //               </div>
  //               <div className="col-md-6 text-end">
  //                 <p className="font-weight-bold mb-1">
  //                   {`${t("invoice")}: #${invoice.data?.id}`}
  //                 </p>
  //                 <p className="text-muted">
  //                   Date: {formatDate(invoice.data?.date)}
  //                 </p>
  //                 <button
  //                   type="button"
  //                   className="btn btn-labeled btn-primary print-button"
  //                   onClick={handlePrint}
  //                 >
  //                   <span className="btn-label">
  //                     <i className="fa fa-print"></i>
  //                   </span>{" "}
  //                   {t("print")}
  //                 </button>
  //               </div>
  //             </div>

  //             <hr className="my-1" />

  //             <div className="row pb-1 p-1">
  //               <div className="col-md-6">
  //                 <p className="font-weight-bold mb-4">
  //                   {t("client_information")}
  //                 </p>
  //                 <p className="mb-1">{invoice?.data?.clientInfo?.name}</p>
  //                 <p className="mb-1">{invoice.data?.clientInfo?.email}</p>
  //                 <p className="mb-1">{invoice.data?.clientInfo?.mobile}</p>
  //               </div>

  //               <div className="col-md-6 text-end">
  //                 <p className="font-weight-bold mb-4">{t("payment_details")}</p>
  //                 <p className="mb-1">
  //                   <span className="text-muted">{t("paymentMethod")}: </span>
  //                   {invoice?.paymentMethod}
  //                 </p>
  //               </div>
  //             </div>

  //             <div className="row p-1">
  //               <div className="col-md-12">
  //                 <table className="table">
  //                   <thead>
  //                     <tr>
  //                       {/* <th className="text-uppercase small font-weight-bold">
  //                         #
  //                       </th> */}
  //                       <th className="text-uppercase small font-weight-bold">
  //                         {t("type")}
  //                       </th>
  //                       <th className="text-uppercase small font-weight-bold">
  //                         {t("date")}
  //                       </th>
  //                       <th className="text-uppercase small font-weight-bold text-end">
  //                         {t("amount")}
  //                       </th>
  //                     </tr>
  //                   </thead>
  //                   <tbody>
  //                     <tr key={invoice?.data?.id}>
  //                       <td>{invoice?.data?.type}</td>
  //                       <td>{invoice?.data?.date}</td>
  //                       <td className="text-end">
  //                         {`${
  //                           userSelectedCurrency.symbolLeft
  //                         } ${CurrencyConverter(
  //                           invoice?.data?.amount,
  //                           conversionFactor
  //                         )}`}
  //                       </td>
  //                     </tr>
  //                     <tr>
  //                       <td>TAX</td>
  //                       <td></td>
  //                       <td className="text-end">{`${
  //                         userSelectedCurrency.symbolLeft
  //                       } ${CurrencyConverter(
  //                         invoice?.data?.totalAmount - invoice?.data?.amount,
  //                         conversionFactor
  //                       )}`}</td>
  //                     </tr>
  //                   </tbody>
  //                 </table>
  //               </div>
  //             </div>

  //             <div className="d-flex flex-row-reverse text-white invoice-total p-4">
  //               <div className="py-1 px-1 text-end">
  //                 <div className="mb-2" style={{ color: "black" }}>
  //                   {t("grand_total")}
  //                 </div>
  //                 <div
  //                   className="h2 font-weight-light"
  //                   style={{ color: "black" }}
  //                 >
  //                   {`${userSelectedCurrency.symbolLeft} ${CurrencyConverter(
  //                     invoice.data?.totalAmount,
  //                     conversionFactor
  //                   )}`}
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </>
  //   );
};

export default PurchaseInvoice;
