import { useTranslation } from "react-i18next";
import { ApiHook } from "../../hooks/apiHook";
import InvoiceList from "../../components/invoice/InvoiceList";
import Loader from "react-js-loader";

const Invoice = () => {
  const { t } = useTranslation();
  const { data, isLoading } = ApiHook.CallPurchaseReports();
  console.log(data, "data");
  if (isLoading) {
    return (
      <>
        <Loader type="bubble-scale" bgColor={"black"} size={30} />
      </>
    );
  } else if (data && data.length > 0) {
    return (
      <>
        <div className="page_head_top">{t("Invoice")}</div>
        {/* <UpgradeComponents data={productsList} /> */}
        <InvoiceList data={data ?? []} />
      </>
    );
  } else if (data && !data?.data?.status) {
    return (
      <>
        <div className="page_head_top">{t("Invoice")}</div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <h6>{t(data?.data?.data?.description)}</h6>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="page_head_top">{t("Invoice")}</div>
      {/* <UpgradeComponents data={productsList} /> */}
      <InvoiceList data={data ?? []} />
    </>
  );
};

export default Invoice;
