import { useState, useRef } from "react";
import LabelledButton from "../buttons/LabelledButton";
import SubmitButton from "../buttons/SubmitButton";
import { useTranslation } from "react-i18next";

const PayoutTableFilter = ({ setActiveTab, activeTab, data }) => {
  const [selectedValue, setSelectedValue] = useState(activeTab);

  const { t } = useTranslation();

  const handleTabChange = (e) => {
    
    const selectedValue = document.getElementById("category").value;
    if (e.target.id === "searchButton") {
      setActiveTab(selectedValue);
    } else if (e.target.id === "resetButton") {
      setActiveTab("paid");
    }
  };
  const handleSelectChange = (e) => {
    setSelectedValue(e.target.value);
  };

  return (
    <>
      <div className="filter_Section">
        <div className="row justify-content-between">
          <div className="col-md-1">
             <div className="ewallet_table_section_cnt">
               <div className="ewallet_table_section_cnt_tab_head">
              <div className="ewallet_tab_btn active">
                      {t("Statement")}
              </div>      
            </div>
            </div>
          </div>
          <div className="col-md-4 text-end mob_filter_right">
            <LabelledButton
              className="fa fa-file-excel"
              text=" Excel"
              data={data}
              fileName={activeTab}
            />
            <LabelledButton
              className="fa fa-file-excel"
              text=" CSV"
              data={data}
              fileName={activeTab}
            />
            <LabelledButton
              className="fa fa-print"
              text=" Print"
              data={data}
              fileName={activeTab}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PayoutTableFilter;
