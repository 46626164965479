import React, { useEffect, useState } from "react";
import Web3 from "web3";
import usdt_abi from "./abi/bsc_usdt";
import test_usdt_bsc_abi from "./abi/bsc_usdt_test";
import { app_status } from "../../../config/config";

const ConnectMetamask24 = ({
  oninitiate,
  onsubmit,
  amount,
  toAddress,
  title,
  style,
  metaMaskLoading = false,
  disabled = false,
}) => {
  // amount = 0.001;
  // toAddress="0x4d32476028b0517b980071fe78287302B1b566ea";
  const [web3, setWeb3] = useState();
  const [contract, setContract] = useState();

  const usdt_bsc = "0x55d398326f99059fF775485246999027B3197955";
  const usdt_test_bsc = "0x68a994Ca1861DF23d968d466bF6a7165CFaD8d48";

  const contractaddress = app_status == "LIVE" ? usdt_bsc : usdt_test_bsc;
  const contractabi = app_status == "LIVE" ? usdt_abi : test_usdt_bsc_abi;

  useEffect(() => {
    const _web3 = new Web3(window.ethereum);
    const _contract = new _web3.eth.Contract(contractabi, contractaddress);
    setWeb3(_web3);
    setContract(_contract);
  }, []);

  async function startTransaction() {
    if (!toAddress || !amount) {
      onsubmit({
        status: false,
        message: "toAddress, amount are required!",
        data: { toAddress, amount },
      });
    }
    const value = web3.utils.toWei(amount.toString(), "ether");

    try {
      if (typeof window.ethereum !== "undefined") {
        console.log("Wallet is installed!");
      } else {
        alert("Wallet is not installed!");
        onsubmit({
          status: false,
          message: "Wallet is not installed!",
          data: null,
        });
        return;
      }
    } catch (checkError) {
      console.log("checkError " + checkError);
      onsubmit({
        status: false,
        message: "Something went wrong!",
        data: checkError,
      });
      return;
    }
    var provider = window.ethereum;

    //Ask for connection and get user address from metamask
    try {
      var accounts = await provider.request({ method: "eth_requestAccounts" });
      console.log("address received : " + accounts[0]);
    } catch (reqError) {
      if (reqError.code == 4001) {
        console.log("User rejected the request");
        onsubmit({
          status: false,
          message: "User rejected the request",
          data: reqError,
        });
      } else {
        console.log("reqError", reqError);
        onsubmit({
          status: false,
          message: "Something went wrong!",
          data: reqError,
        });
      }
      return;
    }

    //Check for chain and switch
    const chainId = app_status == "LIVE" ? "0x38" : "0x61";
    const chain_params =
      app_status == "LIVE"
        ? {
          chainId: chainId,
          chainName: "Binance Smart Chain",
          nativeCurrency: {
            name: "BNB",
            symbol: "bnb",
            decimals: 18,
          },
          rpcUrls: ["https://bsc-dataseed.binance.org/"], // BSC RPC endpoint
          blockExplorerUrls: ["https://bscscan.com/"], // BSC block explorer
        }
        : {
          chainId: chainId, // BSC Testnet chain ID
          chainName: "Binance Smart Chain Testnet",
          nativeCurrency: {
            name: "BNB",
            symbol: "tbnb",
            decimals: 18,
          },

          rpcUrls: [
            "https://responsive-fragrant-breeze.bsc-testnet.quiknode.pro/b8db5bae9449766de5957a24769c563b9233276a/",
          ], // BSC Testnet RPC endpoint
          // rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"], // BSC Testnet RPC endpoint
          blockExplorerUrls: ["https://testnet.bscscan.com/"], // BSC Testnet block explorer
        };

    try {
      await provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: chainId }],
      });
      console.log("Network switched to BSC");
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await provider.request({
            method: "wallet_addEthereumChain",
            params: [chain_params],
          });
          console.log("Added BSC chain");
        } catch (addError) {
          console.log("Error adding chain");
          onsubmit({
            status: false,
            message: "Error adding chain!",
            data: addError,
          });
          return;
        }
      } else {
        console.log("Error switching chain");
        onsubmit({
          status: false,
          message: "Error switching chain!",
          data: switchError,
        });
        return;
      }
    }

    //check bnb balance
    try {
      var bnb_balance = web3.utils.fromWei(
        await web3.eth.getBalance(accounts[0]),
        "ether"
      );
      console.log("bnb_balance", bnb_balance);
      if (bnb_balance == 0) {
        console.log("Insufficient BNB Balance");
        onsubmit({
          status: false,
          message: "Insufficient BNB Balance! (" + bnb_balance + ")",
          data: null,
        });
        return;
      }
    } catch (balFetchError) {
      console.log(balFetchError);
      return;
    }

    //check usdt balance
    try {
      var usdt_balance = web3.utils.fromWei(
        await contract.methods.balanceOf(accounts[0]).call(),
        "ether"
      );
      console.log("usdt_balance-------->", usdt_balance);

      if (parseFloat(usdt_balance) < parseFloat(amount)) {
        console.log("Insufficient USDT Balance");
        onsubmit({
          status: false,
          message: "Insufficient USDT Balance! (" + usdt_balance + ")",
          data: null,
        });
        return;
      }
    } catch (balFetchError) {
      console.log(balFetchError);
      return;
    }

    //Initiate token transfer
    try {
      try {
        const estimatedGas = await contract.methods
          .transfer(toAddress, value)
          .estimateGas({ from: accounts[0] });
        console.log("estimatedGas--", estimatedGas);
        var gasToSend = parseInt(estimatedGas) + 5000;
        console.log("gasToSend--", gasToSend);
      } catch (err) {
        console.log("estimatedGas failed--", JSON.stringify(err));
        var gasToSend = 60000;
      }
      var gasPrice = await web3.eth.getGasPrice();
      gasPrice = (gasPrice * 1.01).toFixed();
      console.log(`toAddress: ${toAddress}, value: ${value}, gasPrice: ${gasPrice}, gasToSend: ${gasToSend}`);

      const transaction = contract.methods
        .transfer(toAddress, value)
        .send({ from: accounts[0], gasPrice, gas: gasToSend });
      transaction
        .on("transactionHash", function (hash) {
          console.log("Transaction Hash:", hash);
          //ajax call for transaction initialized
          oninitiate({ status: true, message: "Transaction initiated.", hash });
        })
        .on("error", (error) => {
          console.error("Transaction Error:", error.message);
        });
      transaction
        .then((trdet) => {
          const txhash = trdet.transactionHash;
          if (trdet.status == true) {
            console.log("transaction success");
            console.log(txhash);
            onsubmit({
              status: true,
              message: "Transaction success",
              data: trdet,
            });
            //ajax call for transaction success
          }
        })
        .catch((err) => {
          if (err.code == 4001) {
            console.log("User denied transaction");
            onsubmit({
              status: false,
              message: "User denied transaction!",
              data: err,
            });
          } else {
            console.log("Promise failed:", err);
            onsubmit({
              status: false,
              message: "Something went wrong!",
              data: err,
            });
          }

          return;
        });
    } catch (transferError) {
      console.log("transferError :" + transferError);
      onsubmit({
        status: false,
        message: "Something went wrong!",
        data: transferError,
      });
      return;
    }
  }

  return (
    <div>
      <button
        disabled={metaMaskLoading || disabled}
        className="btn btn-sm btn-primary mt-3"
        onClick={startTransaction}
        style={{
          border: "2px solid black",
          paddingRight: 50,
          paddingLeft: 50,
          ...style,
        }}
      >
        {metaMaskLoading ? "proccessing" : title ?? "Send USDT"}
      </button>
    </div>
  );
};

export default ConnectMetamask24;
