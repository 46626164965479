import React from 'react';
import Iframe from 'react-iframe';

const EmbedIframe = ({ data }) => {
  return (
    <div className="embedded_container">
      {data.map((single) => (
        <div className="embedded_item" key={single.id}>
          <h6>{single.title}</h6>
          <span>{single.note ?? ''}</span>
          <Iframe
            url={single.content.match(/src="(.*?)"/)[1]}
            width="100%"
            height="800px"
            id={`iframe-${single.id}`}
            className="embedded_column"
            display="initial"
            position="relative"
          />
        </div>
      ))}
    </div>
  );
};

export default EmbedIframe;