import React, { useState } from "react";
import TreeViewNavbar from "../../components/Tree/TreeViewNavbar";
import TreeViewContent from "../../components/Tree/tree-view/TreeViewContent";
import { ApiHook } from "../../hooks/apiHook";

const TreeView = () => {
  const [selectedUserId, setSelectedUserId] = useState("");
  const treeListData = ApiHook.CallTreeViewList(selectedUserId);
  if (treeListData.isFetching) {
    // return null;
  }

  return (
    <>
      <TreeViewNavbar menu={"treeView"} />
      <TreeViewContent
        setSelectedUserId={setSelectedUserId}
      />
    </>
  );
};

export default TreeView;
