import TreeViewNavbar from "../../components/Tree/TreeViewNavbar";
import { useState } from "react";
import UnilevelTreeContent from "../../components/Tree/unilevel-tree/UnilevelTreeContent";

const UnilevelTree = () => {
  const [selectedUserId, setSelectedUserId] = useState("");
  const [searchUsername, setSearchUsername] = useState("");
  const [doubleClickedUser, setDoubleClickedUser] = useState("");

  return (
    <>
      <TreeViewNavbar
        menu={"unilevel-tree"}
        searchUsername={searchUsername}
        setSearchUsername={setSearchUsername}
      />
      <UnilevelTreeContent
        selectedUserId={selectedUserId}
        setSelectedUserId={setSelectedUserId}
        doubleClickedUser={doubleClickedUser}
        setDoubleClickedUser={setDoubleClickedUser}
        searchUsername={searchUsername}
        setSearchUsername={setSearchUsername}
      />
    </>
  );
};

export default UnilevelTree;
