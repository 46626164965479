import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import CurrencyConverter from "../../Currency/CurrencyConverter";

const InvoiceList = ({ data }) => {
  const itemsPerPage = 10; // Adjust based on your preference
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="shoping_page_section">
      <div className="row">
        <div className="ewallet_table_section">
          <div className="ewallet_table_section_cnt">
            <div className="table-responsive min-hieght-table">
              <div className="table-container">
                <table className="striped" style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th style={{ paddingRight: "5px" }}>Invoice No</th>
                      <th style={{ paddingRight: "5px" }}>Type</th>
                      <th style={{ paddingRight: "5px" }}>Amount</th>
                      <th style={{ paddingRight: "5px" }}>Date</th>
                      <th style={{ paddingRight: "5px" }}>Total Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <NavLink to={`/invoice/${item.id}`}>
                            {item.id}
                          </NavLink>
                        </td>
                        <td>{item.type}</td>
                        <td>$ {item.amount}</td>
                        <td>{item.date}</td>
                        <td>$ {item.totalAmount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="pagination">
          {[...Array(totalPages).keys()].map((number) => (
            <button key={number} onClick={() => paginate(number + 1)}>
              {number + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default InvoiceList;
