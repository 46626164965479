import EmbedIframe from "../../components/Unilevel/EmbedIframe";
import { ApiHook } from "../../hooks/apiHook";
import Loader from "react-js-loader";

const AICoaches = () => {
  const productsList = ApiHook.CallEmbeddedCodes();
  console.log(productsList.data, "productsList");

  if (productsList.isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
          position: "fixed",
          top: 0,
          left: 0,
        }}
      >
        <Loader
          type="spinner-default"
          bgColor={"#954cea"}
          color={"#954cea"}
          size={50}
        />
      </div>
    );
  }
  if (productsList.data?.length <= 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
          position: "fixed",
          top: 0,
          left: 0,
        }}
      >
        <h6>EMPTY</h6>
      </div>
    );
  }
  return (
    <>
      <div className="page_head_top">AI Coaches</div>
      <EmbedIframe data={productsList.data} />
    </>
  );
};
export default AICoaches;
