import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../hooks/apiHook";
import SubmitButton from "../../components/Common/buttons/SubmitButton";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

const ReplicaSite = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  // const replicaFlag = ApiHook.CallGetReplicaBanner();
  const Upload = ApiHook.CallUploadReplicaBanner(File);
  const [fileResponse, setFileResponse] = useState({
    success: null,
    error: null,
  });
  const [file, setFile] = useState([]);

  const handleFileChange = (event) => {
    setFileResponse({
      success: null,
      error: null,
    });
    const selectedFile = event.target.files;
    setFile(selectedFile);
  };
  const handleUpload = () => {
    if (file) {
      Upload.mutate(file, {
        onSuccess: (res) => {
          if (res.status) {
            toast.success(res?.data?.messag);
            queryClient.invalidateQueries({ queryKey: ["get-replica-banner"] });
          } else {
            toast.error(t(res?.data?.description));
          }
        },
      });
    }
  };

  return (
    <>
      <div className="page_head_top">Replica Site</div>
      <div className="uploadMainBg">
        <div className="currentBannerSec">
          <h3>Current Top Banner</h3>
          {/* <img src={replicaFlag?.data?.image} alt="" /> */}
        </div>
        <h3>Upload Top Banner</h3>
        <div className="uploadSubBg">
          <input
            type="file"
            placeholder=""
            className="form-control"
            name="fileUpload"
            onChange={handleFileChange}
            multiple
          />
          {fileResponse?.success && (
            <div style={{ color: "green" }}>{t(fileResponse?.success)}</div>
          )}
          {fileResponse?.error && (
            <div style={{ color: "red" }}>{t(fileResponse?.error)}</div>
          )}
          <img src="images/upload.png" alt="" />
          <h6>{t('choose_an_image_file_or_drag_it_here')}</h6>
          <p>{t('please_choose_a_Png/Jpeg/Jpg_file')}</p>
          <p>{t('max_size_2MB')}</p>
          <p>{t('resolution_recommeded_1920x1080')}</p>
          <div style={{textAlign:'center'}}>
            <SubmitButton
              className="upload_btn"
              click={handleUpload}
              text={Upload?.isLoading ? "Uploading..." : t("upload")}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReplicaSite;
