import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "../../components/payment/CheckoutForm";

const Stripe = ({ clientSecret, handleSubmit, publicKey }) => {
  const stripePromise = loadStripe(publicKey);
  const options = {
    clientSecret: clientSecret,
  };
  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm handleSubmitFinish={handleSubmit} />
    </Elements>
  );
};

export default Stripe;
