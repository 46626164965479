import React from "react";
import SubmitButton from "../Common/buttons/SubmitButton";

const UpgradeComponents = ({ data }) => {
  return (
    <div className="package_upgrade_cnt_sec">
      <div className="plan-options">
        {data?.map((item, index) => (
          <>
            {!item?.currentPack ? (
              <div className="plan" key={index}>
                <div className="plan-header">
                  <h3 className="plan-heading">{item?.name}</h3>
                </div>
                <ul className="plan-feature">
                  <li>PV : {item?.pairValue}</li>
                  <li>Validity (months) : {item?.validity}</li>
                </ul>
                <p>
                  $<span className="plan-amount">{item?.price}</span>
                  <SubmitButton className="plan-choose" text="upgrade" isSubmitting={!item?.upgradable}/>
                </p>
              </div>
            ) : (
              <div className="plan current_plan" key={index}>
                <div className="plan-header">
                  <h3 className="plan-heading">{item?.name}</h3>
                </div>
                <ul className="plan-feature">
                  <li>PV : {item?.pairValue}</li>
                  <li>Validity (months) : {item?.validity}</li>
                </ul>
                <p>
                  $<span className="plan-amount">{item?.price}</span>
                  <button className="plan-choose">Current Plan</button>
                </p>
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default UpgradeComponents;
