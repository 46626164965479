export const setInertState = (shouldBeInert) => {
  const body = document.body;
  if (shouldBeInert) {
    body.style.pointerEvents = "none";
    body.style.userSelect = "none";
  } else {
    body.style.pointerEvents = "";
    body.style.userSelect = "";
  }
};
