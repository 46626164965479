import React, { useState, useRef } from "react";
import Input from "./FormInput.jsx";
import logo_user from "../../assests/images/logo_user.png";
import welcomeImg from "../../assests/images/welcomeImg.svg";
import SubmitButton from "../Common/buttons/SubmitButton.jsx";
import { ApiHook } from "../../hooks/apiHook.js";
// import Pay from "../Common/modals/Pay.jsx";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { use } from "i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const LoginForm = ({ params }) => {
  const [credentials, setCredentials] = useState({
    username: params.username ?? "",
    password: params.username ? "123456" : "",
  });
  const [errorMessage, setErrorMessage] = useState({
    username: null,
    password: null,
    userCredentials: null,
  });
  const isSubmittingRef = useRef(false);
  const verifyOtp = ApiHook.Call2faVerifyOtp();
  const loginMutation = ApiHook.CallLoginUser();
  const forgotPasswordMutation = ApiHook.CallForgotPassword();
  const replica = ApiHook.CallreplicaLink();

  console.log(replica?.replicaLink, "replica");

  const [otp, setotp] = useState("");
  const { isEnabled, twoFadata } = useSelector(
    (state) => state?.user?.twoFaAuth
  );
  const handleOtpChange = (e) => {
    const { value } = e.target;
    setotp(value);
  };

  const handleVerifyotp = async (e) => {
    e.preventDefault();
    try {
      await verifyOtp.mutateAsync(
        {
          admin_user_name: credentials.admin_user_name,
          username: credentials.username,
          password: credentials.password,
          secretKey: twoFadata?.secretKey,
          otp: otp,
        },
        {
          onSuccess: (res) => {
            console.log(res);
            if (res?.code === 1132) {
              setErrorMessage({
                userCredentials: res?.data,
              });
            } else if (res?.code === 1037) {
              console.log("dsds", res);
              setErrorMessage({
                userCredentials: res?.data,
              });
            } else {
              setErrorMessage({
                userCredentials: res?.data,
              });
            }
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
    setErrorMessage((prev) => ({
      ...prev,
      [name]: null,
    }));

    setErrorMessage((prev) => ({
      ...prev,
      userCredentials: null,
    }));

    if (value === null || value === "") {
      setErrorMessage((prev) => ({
        ...prev,
        [name]: "*Required",
      }));
    }
  };
  const isFormValid = () => {
    return (
      credentials?.password.trim() !== "" && credentials?.username.trim() !== ""
    );
  };

  const handleForgotPassword = () => {
    forgotPasswordMutation.mutate(
      { username: credentials.username },
      {
        onSuccess: (res) => {
          if (res.status) {
            toast.success(res?.data);
          } else {
            toast.error(res?.description);
            setErrorMessage((prev) => ({
              ...prev,
              username: "*Required",
            }));
          }
        },
      }
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!isSubmittingRef.current) {
      isSubmittingRef.current = true;
      await loginMutation.mutateAsync(credentials, {
        onSuccess: (res) => {
          if (res?.code === 1003) {
            setErrorMessage({
              userCredentials: res?.data,
            });
          }
        },
      });
      isSubmittingRef.current = false;
    }
  };

  return (
    <>
      <div className="col-md-6 logincredDetail">
        <div className="loginFormSec login_left_section">
          <div className="loginLogo">
            <img src={logo_user} alt="" />
          </div>
          <p>Welcome Back to InfoByte</p>
          <div className="loginFormSec"></div>

          {isEnabled && isEnabled ? (
            <div>
              {errorMessage?.userCredentials && (
                <div style={{ color: "red", textAlign: "center" }}>
                  {errorMessage?.userCredentials}
                </div>
              )}
              {twoFadata?.showQr && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className="qrcode-container">
                    <img src={twoFadata?.authQrCode} alt="qr code" />
                  </div>
                  <p>Please scan Google Authenticator QR code</p>
                </div>
              )}
              <form onSubmit={handleVerifyotp}>
                <Input
                  type="text"
                  id="admin"
                  name="admin_user_name"
                  placeholder="OTP"
                  value={otp}
                  onChange={handleOtpChange}
                />
              </form>

              <div className="loginBtn">
                <SubmitButton
                  isSubmitting={verifyOtp?.isLoading}
                  click={handleVerifyotp}
                  text={verifyOtp.isLoading ? "Processing..." : "Verify"}
                  className={"btn"}
                />
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              {errorMessage?.userCredentials && (
                <div style={{ color: "red", textAlign: "center" }}>
                  {errorMessage?.userCredentials}
                </div>
              )}
              <Input
                type="text"
                id="fname"
                name="username"
                placeholder="Username"
                value={credentials.username}
                onChange={handleChange}
              />
              {errorMessage?.username && (
                <div style={{ color: "red" }}>{errorMessage?.username}</div>
              )}
              <Input
                type="password"
                id="password"
                name="password"
                placeholder="Password"
                value={credentials.password}
                onChange={handleChange}
              />
              {errorMessage?.password && (
                <div style={{ color: "red" }}>{errorMessage?.password}</div>
              )}
              <Link onClick={handleForgotPassword}>Forgot Password?</Link>
              <div className="loginBtn">
                <SubmitButton
                  isSubmitting={!isFormValid()}
                  click={handleSubmit}
                  text={loginMutation.isLoading ? "Submitting..." : "Login"}
                  className={"btn"}
                />
              </div>
              <p>
                Don't have an account?{" "}
                <a
                  href={replica?.replicaLink}
                  style={{
                    fontSize: "16px",
                    textDecoration: "underline",
                    color: "rgb(61 66 195)",
                  }}
                >
                  Signup now
                </a>
              </p>
            </form>
          )}
        </div>
      </div>
      <div className="col-md-6">
        <div className="welcomeImgSec">
          <div className="welcomHeadSec">
            <p>Hello,</p>
            <h2>Welcome</h2>
            <p>Enter your credentials and login</p>
          </div>
          <div className="welcomeImg">
            <img src={welcomeImg} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
