import React from "react";
import { useTranslation } from "react-i18next";

const SubmitButton = ({ id, isSubmitting, click, text, className }) => {
  const { t } = useTranslation();
  return (
    <>
      <button
        id={id}
        className={className}
        type="submit"
        disabled={isSubmitting}
        onClick={click}
      >
        {t(text)}
      </button>
    </>
  );
};

export default SubmitButton;
