import { useState } from "react";
import { toast } from "react-toastify";
import { ApiHook } from "../../hooks/apiHook";
import LoadingOverlay from "../Common/LoadingOverlay";
import Stripe from "../../views/payment/Stripe";
import Modal from "react-bootstrap/Modal";

const StripeUnilevelPayment = ({ props }) => {
  const [pay, setPay] = useState(false);
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [stripeIntentStatus, setStripeIntentStatus] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const unilevelPayment = ApiHook.MakeUnilevelPayment();
  const stripeIntent = ApiHook.CallCreateStripePaymentIntent();
  const handleSubmit = (paymentMethod, paymentIntentResponse) => {
    console.log({ paymentIntentResponse, paymentMethod });
    const body = {
      paymentIntentId: paymentIntentId,
      paymentResponse: paymentIntentResponse,
      type: "stripe",
    };
    unilevelPayment.mutateAsync(body);
  };

  const handleButtonClick = () => {    
    setPay(!pay);
    stripeIntent.mutateAsync(
      {
        type: "subscription",
      },
      {
        onSuccess: (res) => {
          if (res.status) {
            setClientSecret(res.client_secret);
            setPaymentIntentId(res.id);
            setStripeIntentStatus(res.status);
          } else {
            toast.error(res.description);
          }
        },
      }
    );
  };

  const handleClose = () => {
    setPay(false);
  };

  return (
    <div>
      <button className="btn btn-primary checkout_cnt_btn" onClick={handleButtonClick}>Stripe Subscription</button>
      {pay && stripeIntentStatus && (
        <Modal show={pay && stripeIntentStatus} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Pay Unilevel Fee</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Stripe
              clientSecret={clientSecret}
              handleSubmit={handleSubmit}
              publicKey={
                props?.stripeDetails?.["PaymentGatewayDetail.publicKey"]
              }
            />
          </Modal.Body>
        </Modal>
      )}
      <LoadingOverlay isLoading={unilevelPayment.isLoading} />
    </div>
  );
};

export default StripeUnilevelPayment;
