import React from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../utils/formateDate";

import { useSelector } from "react-redux";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { TableSkeleton } from "./table/TableSkeleton";

export const ComponentToPrint = React.forwardRef((props, ref) => {
  const userSelectedCurrency = useSelector(
    (state) => state.user?.selectedCurrency
  );

  const { t } = useTranslation();

  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );
  console.log(props.CompanyProfile["name"]);

  const headers = [t("date"), t("amount"), t("payout_method")];

  return (
    <div ref={ref}>
      <div className="page_head_top" style={{ color: "green" }}>
        {t("payout")}
      </div>
      {props.CompanyProfile && (
        <div>
          <strong>Company Name : {props.CompanyProfile["name"]}</strong>
          <br></br>
          <strong>Company Address : {props.CompanyProfile["address"]}</strong>
          <br></br>
          <div className="nodata-table-view-box-img">
            <img src={props.CompanyProfile["logo"]} alt="" />
          </div>
        </div>
      )}

      <div className="ewallet_top_btn_sec">
        <div className="row justify-content-between">
          <div className="col-md-4"></div>
          <div className="table-container">
            <table className="striped" style={{ width: "100%" }}>
              <thead>
                <tr>
                  {headers.map((header, index) => (
                    <th key={index}>{header}</th>
                  ))}
                </tr>
              </thead>

              {props.type === "payout" && !props.data ? (
                <TableSkeleton rowCount={6} cellCount={3} />
              ) : (
                props.type === "payout" && (
                  <tbody>
                    {props.data.map((row, rowIndex) => (
                      <tr key={rowIndex}>
                        {row.Date && <td>{formatDate(row.Date)}</td>}
                        {row.Amount && (
                          <td>
                            <span>
                              {`${
                                userSelectedCurrency?.symbolLeft
                              } ${CurrencyConverter(
                                row.Amount,
                                conversionFactor
                              )} `}
                            </span>
                          </td>
                        )}
                        {row.PaymentMethod && (
                          <td>
                            <span>{t(`${row.PaymentMethod}`)}</span>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                )
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
});
