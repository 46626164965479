import React, { useRef, useState, useEffect } from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import { ComponentToPrint } from "../ComponentToPrint";
import { ApiHook } from "../../../hooks/apiHook";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";

const LabelledButton = ({ text, className, data, fileName }) => {
  const { t } = useTranslation();
  const componentRef = useRef(null);
  const [print, setPrint] = useState(false);
  const activeTab = fileName;
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const file = "Payout_" + fileName;

  const payoutDetails = ApiHook.CallPayoutExportData(activeTab);

  const exportData = (text) => {
    let Data;
    if (text === " Excel") {
      if (payoutDetails?.data?.payoutDetails.length === 0) {
        Data = [{ Date: "" }, { Amount: "" }, { PayoutMethod: "" }];
      } else {
        Data = payoutDetails?.data?.payoutDetails;
      }
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const ws = XLSX.utils.json_to_sheet(Data);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, file + fileExtension);
    } else if (text === " CSV") {
      const fileType = "text/csv;charset=UTF-8";
      const fileExtension = ".csv";

      const csvContent =
        "Date,Amount,Payment Method\n" +
        payoutDetails?.data?.payoutDetails
          .map((row) => Object.values(row).join(","))
          .join("\n");

      const data = new Blob([csvContent], { type: fileType });
      FileSaver.saveAs(data, file + fileExtension);
    } else if (text === " Print") {
      setPrint(true);
      handlePrint();
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-labeled btn-primary"
        onClick={(e) => exportData(text)}
      >
        <span className="btn-label">
          <i className={className}></i>
        </span>
        {text}
      </button>

      <div style={{ display: "none" }}>
        <ComponentToPrint
          ref={componentRef}
          data={payoutDetails?.data?.payoutDetails ?? []}
          CompanyProfile={payoutDetails?.data?.companyProfile ?? {}}
          type="payout"
        />
      </div>
    </>
  );
};

export default LabelledButton;
