import React from "react";

const FooterSection = () => {
  const d = new Date();
  let year = d.getFullYear();
  return (
    <footer className="footer_copy">
      <div className="container">
        <p>Copyright @ All right reserved. {year}</p>
      </div>
    </footer>
  );
};

export default FooterSection;
