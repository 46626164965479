import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { setInertState } from '../../utils/inertUtil';

const LoadingOverlay = ({ isLoading }) => {
  useEffect(() => {
    setInertState(isLoading);

    return () => {
      setInertState(false);
    };
  }, [isLoading]);

  if (!isLoading) return null;

  return ReactDOM.createPortal(
    <div className="loading-overlay">
      <div className="spinner"></div>
    </div>,
    document.body
  );
};

export default LoadingOverlay;
