import { useTranslation } from "react-i18next"
import UpgradeComponents from "../../components/upgrade/UpgradeComponents";
import { ApiHook } from "../../hooks/apiHook";


const Upgrade = () => {
    const { t } = useTranslation();
    const productsList = ApiHook.CallGetUpgradeProducts()
    return (
        <>
            <div className="page_head_top">{t("upgrade")}</div>
            <UpgradeComponents data={productsList}/>
        </>
    )
}

export default Upgrade
