
//LOCAL

// const BASE_URL = "http://192.168.10.40:5002/api/";
// const DEFAULT_KEY = "0b8d966f-13ff-461e-9496-d432445d83ff";
// const app_status = "DEMO";

// //DEMO
// const BASE_URL = "http://162.19.146.135:1328/api/";
// const DEFAULT_KEY = "0b8d966f-13ff-461e-9496-d432445d83ff";
// const app_status = "DEMO";

// //LIVE
const BASE_URL = "https://admin.infobyte.club/api/";
const DEFAULT_KEY = "0b8d966f-13ff-461e-9496-d432445d83ff";
const app_status = "LIVE";


export { BASE_URL, DEFAULT_KEY, app_status }