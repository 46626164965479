import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import SubmitButton from "../Common/buttons/SubmitButton";
import { useTranslation } from "react-i18next";

const CheckoutForm = ({ handleSubmitFinish }) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    setIsLoading(true);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: "",
      },
      redirect: "if_required",
    });

    if (result.error) {
      setIsLoading(false);
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(result.error.message);
    } else {
      handleSubmitFinish(5, result.paymentIntent);
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <SubmitButton
        className={"btn btn-primary checkout_cnt_btn"}
        isSubmitting={!stripe}
        text={"submit"}
        isLoading={isLoading}
      />
      {/* Show error message to your customers */}
      {errorMessage && <div>{t(errorMessage)}</div>}
    </form>
  );
};

export default CheckoutForm;
